    /* for desktop */
    .whatsapp_float {
        position: fixed;
        width: 105px;
        height: 60px;
        bottom: 40px;
        right: 0px;
        background-color: #25d366;
        color: #FFF;
        border-radius: 30px 3px 3px 30px;
        text-align: center;
        font-size: 30px;
        box-shadow: 2px 2px 3px #999;
        z-index: 600;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    /* for mobile */
    @media screen and (max-width: 767px) {

        .whatsapp_float {
            width: 95px;
            height: 40px;
            bottom: 20px;
            right: 0;
            font-size: 22px;
        }
    }

        @media screen and (max-width: 425px) {

        .whatsapp_float {
            bottom: 35px;   
        }
    }
