.project-details-container{
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 30px;
}

.project-details-wrapper{
    width: 750px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.details-image{
    width: 500px;
    height: 350px;
    border-radius: 8px;
    object-fit: cover;
    object-position: top;
}

.details-title{
    width: 100%;
    margin: 5px;
}

@media screen and (max-width: 650px){
    .project-details-container{
        margin-top: 95px;
    }
    .project-details-wrapper{
        width: 90%;
    }
    .details-image{
        width: 100%;
        height: 300px;
    }
    .details-title{
        width: 100%;
        margin: 5px;
    }
}